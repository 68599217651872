import Vue from 'vue'
import App from './App.vue'
import './mainjs/importjs'
import router from './router'
import './router/permission'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0)  
//   setTimeout(()=>{
//     next()
//   },100)
// })