import "@/mainjs/registerServiceWorker";
import Vue from "vue";
import axios from "axios";
Vue.prototype.axios = axios;
import { post, get, respTip } from "@/utils/baseRequest";
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$tip = respTip;
Vue.config.productionTip = false;

import { Urls } from "@/utils/urlManager";
Vue.prototype.$Urls = Urls;
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import { Notify, Toast } from "vant";
// 全局注册
Vue.use(Notify);
Vue.use(Toast);

// import VConsole from 'vconsole';
// const vConsole = new VConsole();

import filters from "@/utils/filters"; // 注册全局fillter
filters(Vue); // 使用filter
