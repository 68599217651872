import router from "@/router";
const redirectUrl = `https://open.pennonedu.com/wechat/official_account?redirect=${process.env.VUE_APP_REDIRECT_API}`;
import axios from "axios";
export async function getToken(to) {

  let code = getQueryVariable("code");
  let state = getQueryVariable("state");
  if (code == false) {
    if (to.name == "VoteHome") {
      localStorage.setItem("FromPage", JSON.stringify({ from: "VoteHome" }));
    } else if (to.name == "PublicUserDetails") {
      localStorage.setItem("FromPage", JSON.stringify({ from: "PublicUserDetails", id: to.query.id }));
    } else if (to.name == "HomePICC") {
      localStorage.setItem("FromPage", JSON.stringify({ from: "HomePICC" }));
    } 
    window.location.href = redirectUrl
    return false;
  } else {
    // 回调有参数了之后再拿token
    let res = await axios({
      method: "get",
      url: `/api/v1/token/get-token?code=${code}&state=${state}`,
    });
    if (res.status == 200) {
      if (res.data.errCode == 10060) {
        window.location.href = redirectUrl
      } else {
        localStorage.setItem("token", res.data.data.token);
        // 知道从哪来的，登录授权后需要跳转到这个页面
        setRouter();
      }
    } else {
      console.log("无法获取token");
    }
  }
}


export function getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// 跳转判断函数
export function setRouter() {
  
  let fromPageStr = localStorage.getItem("FromPage");
  if (fromPageStr) {
    let fromPageJson = JSON.parse(fromPageStr);
    if (fromPageJson.from == "VoteHome") {
      router.replace({ name: "VoteHome" }, () => { }, () => { });
      localStorage.setItem("FromPage",null)
    } else if (fromPageJson.from == "PublicUserDetails") {
      router.replace({ name: "PublicUserDetails", query: { id: fromPageJson.id }, }, () => { }, () => { });
      localStorage.setItem("FromPage", null)
    } else if (fromPageJson.from == "HomePICC") {
      router.replace({ name: "HomePICC" }, () => { }, () => { });
      localStorage.setItem("FromPage", null)
    } else {
      router.replace({ path: "/operation/contest/home" }, () => { }, () => { });
    }
  } else {
    router.replace({ path: "/operation/contest/home" }, () => { }, () => { });
  }
}

export function reload(){
  window.location.href = redirectUrl
}