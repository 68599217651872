import router from "@/router";
import axios from "axios";
import { getToken, reLoad } from "./permissimport";
import { removeToken } from "@/utils/auth";
const whiteList = [
  "RankPicc",
  "Course",
  "ActionEnd",
  "ActionPre",
  "mkptoxd",
  "VoteHomeOutofWX",
  "PublicUserDetailsOutofWX",
  "mkptoxdUserDetails",
  "SelectSubject",
  "RematchSubjectList",
]; // 白名单，不需要验证，直接通行！
router.beforeEach(async (to, from, next) => {
  //  添加白名单，不需要经过验证的直接过
  if (whiteList.indexOf(to.name) >= 0) {
    if (!to.query.t) {
      next({ ...to, query: { ...to.query, t: Date.now() } });
    } else {
      next();
    }
  } else if (localStorage.getItem("token") == null) {
    getToken(to);
  } else {
    // 每次进入页面前验证token是否有效
    let locToken = localStorage.getItem("token");
    let res = await axios({
      method: "get",
      url: "/api/v1/token/verify-token?token=" + locToken,
    });
    if (res.data.data.isValid) {
      if (!to.query.t) {
        next({ ...to, query: { ...to.query, t: Date.now() } });
      } else {
        next();
      }
    } else {
      //无效token 直接清除，刷新界面
      removeToken();
      reload();
    }
  }
});
router.afterEach((to, from) => {
  to.query.t = Date.now();
});
