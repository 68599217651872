import axios from 'axios'
import { Toast } from 'vant';
import { getToken, removeToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  timeout: 5000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (token) {
      config.headers['Token'] = token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.errCode !== 0) {
      Toast(res.errMsg || 'Error')

      // 10101: Illegal token; 10111: Other clients logged in; 10102: Token expired;
      if (
        res.errCode === 10101 ||
        res.errCode === 10111 ||
        res.errCode === 10102
      ) {
        removeToken()
      }
      return Promise.reject(new Error(res.errMsg || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    Toast( error.message)
    return Promise.reject(error)
  }
)

export default service
