import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/operation/contest/home",
    // component: () => import("@/views/path/Login.vue"),
  },
  // {
  //   path: "/mkptoxd",
  //   name: "mkptoxd",
  //   component: () => import("@/views/Test.vue"),
  // },
  // {
  //   path: "/mkptoxdUserDetails",
  //   name: "mkptoxdUserDetails",
  //   component: () => import("@/views/TestUserDetails.vue"),
  // },
  {
    path: "/Course",
    name: "Course",
    component: () => import("@/views/Course.vue"),
  },
  {
    path: "/SelectSubject",
    name: "SelectSubject",
    component: () => import("@/views/SelectSubject.vue"),
  },
  {
    path: "/ActionPre",
    name: "ActionPre",
    component: () => import("@/views/ActionPre.vue"),
  },
  {
    path: "/ActionEnd",
    name: "ActionEnd",
    component: () => import("@/views/ActionEnd.vue"),
  },
  {
    path: "/ReportEnd",
    name: "ReportEnd",
    component: () => import("@/views/ReportEnd.vue"),
  },
  {
    path: "/VoteHomeOutofWX",
    name: "VoteHomeOutofWX",
    component: () => import("@/views/outOfWX/VoteHomeOutofWX.vue"),
  },
  {
    path: "/PublicUserDetailsOutofWX",
    name: "PublicUserDetailsOutofWX",
    component: () => import("@/views/outOfWX/PublicUserDetailsOutofWX.vue"),
  },
  {
    path: "/RankPicc",
    name: "RankPicc",
    component: () => import("@/views/RankPicc.vue"),
  },
  // {
  //   path: "/CourseAgain",
  //   name: "CourseAgain",
  //   component: () => import("@/views/outOfWX/CourseAgain.vue"),
  // },

  {
    path: "/operation",
    name: "operation",
    component: () => import("@/views/path/Operation.vue"),
    children: [
      {
        path: "contest",
        name: "contest",
        component: () => import("@/views/path/Contest.vue"),
        children: [
          {
            path: "Home",
            name: "Home",
            component: () => import("@/views/Home.vue"),
          },
          // {
          //   path: "Work",
          //   name: "Work",
          //   component: () => import("@/views/Work.vue"),
          // },
          {
            path: "User",
            name: "User",
            component: () => import("@/views/User.vue"),
          },
          {
            path: "UserDetails",
            name: "UserDetails",
            component: () => import("@/views/UserDetails.vue"),
          },
          // {
          //   path: "SchoolAddPerson",
          //   name: "SchoolAddPerson",
          //   component: () => import("@/views/SchoolAddPerson.vue"),
          // },
          {
            path: "VoteHome",
            name: "VoteHome",
            component: () => import("@/views/VoteHome.vue"),
          },
          {
            path: "PublicUserDetails",
            name: "PublicUserDetails",
            component: () => import("@/views/PublicUserDetails.vue"),
          },

          {
            path: "Rematch",
            name: "Rematch",
            component: () => import("@/views/Rematch.vue"),
          },
          {
            path: "RematchDetails",
            name: "RematchDetails",
            component: () => import("@/views/RematchDetails.vue"),
          },
          {
            path: "RematchSubjectList",
            name: "RematchSubjectList",
            component: () => import("@/views/RematchSubjectList.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
