import request from '@/utils/request'
import { Toast } from 'vant'
import { getToken, removeToken } from '@/utils/auth'
/**
 *  @auther yuyuanda
 *  在原有request基础上封装post网络请求，使用方法如下：
 *  myUrl：必填地址
 *  params：data必填参数
 *  params：选填参数
 * this.$post('/adminapi/v1/video/vod/category',{"name":'yyd',"age":12},{"name":'yyd',"age":12}).then((response) => {
       console.log(response)
    })
 */
export function post(myUrl, data = {}, params = {}) {
  return request({
    url: myUrl,
    method: 'post',
    params,
    data:{
      ...data,
      token:getToken()
    }
  })
}

/**
 *  @auther yuyuanda
 *  在原有request基础上封装post网络请求，使用方法如下：
 *  myUrl：必填地址
 *  params：选填
 * this.$get('/adminapi/v1/video/vod/category',{"name":'yyd',"age":12}).then((response) => {
       console.log(response)
    })
 */
export function get(myUrl, params = {}) {
  return request({
    url: myUrl,
    method: 'get',
    params:{
      ...params,
      token: getToken()
    }
  })
}

export function respTip(status, tipStr = '操作成功') {
  if (status === 200) {
    Toast.success(tipStr);
    return true
  }
  return false
}
