
const filters = {
  // 时间戳转时间
  timefilter: function (time = '') {
    if(time==''){
      return "请选择出生日期"
    }else{
      let date = new Date(time)
      return `${date.getFullYear()}年${date.getMonth() + 1
        }月${date.getDate()}日`;
    }
  },
}
export default (vm) => {
  Object.keys(filters).forEach(key => {
    vm.filter(key, filters[key])
  })
}
